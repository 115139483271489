<template>
    <div class="bbb">
        <van-nav-bar
            title="我的"
            />
        <div id="Interrogation" class=" time_slot">
            <div class="detail_box">
                <div class="tx_box">
                    <!-- <van-avatar  :figure="5" size="60" :radius="50" /> -->
                    <img class="my_img" src="../assets/image/avatar5.png" alt="">
                    <div class="tx_info">
                        <div class="name">{{ user.name }}</div>
                        <div class="user_account">手机号：{{ user.user_account == 'None' ? '无' : user.user_account }}</div>
                    </div>
                </div> 
                <div class="info_box">
                    <div class="box">
                        <div class="box_value">{{ user.sex == '' ? '未确定' : sex[user.sex] }}</div>
                        <div class="box_label">性别</div>
                    </div>
                    <div class="line"></div>
                    <div class="box">
                        <div class="box_value">{{ user.age == '' ? '未知' : user.age}}</div>
                        <div class="box_label">年龄</div>
                    </div>
                </div>
            </div>
            <div class="m-top">
                <van-cell-group inset>
                    <van-cell title="就诊人" @click="Patient" is-link bind:click="qb" />
                    <van-cell title="问诊中心" @click="order" is-link  />
                    <van-cell title="药品能否统筹查询" @click="getdrug" is-link  />
                    <!-- <van-cell title="上传复诊证明" is-link /> -->
                </van-cell-group>
            </div>
            <div class="m-top">
                <van-button @click="login" color="linear-gradient(to right, #02d3d9, #09e0c0)" type="primary" block>{{login_text}}</van-button>
            </div>
        </div>
        <footer_/>
    </div>
  </template>
  <script>
  import footer_ from '../components/footer.vue'
  export default {
    components: {
      footer_,
    },
    data() {
      return {
        user:{
            name:'',
            phone:'',
            sex:'',
            age:'',
            user_account:'None'
        },
        sex:{
            man:'男',
            girl:'女'
        },
        login_text:'登录'
      }
    },
    methods: {
        login(){
            localStorage.clear();
            sessionStorage.removeItem('patientId');
            sessionStorage.removeItem('Drugs');
            sessionStorage.removeItem('Current');
            sessionStorage.removeItem('disease');
            sessionStorage.removeItem('past');
            sessionStorage.removeItem('family');
            sessionStorage.removeItem('drugSelected');
            // this.$router.push('/login')
            this.$router.push({
                name:'login',
                query:{
                    type:'switch',
                }
            })
            
        },
        Patient(){
            this.$api.article.getihname().then(res=>{
            localStorage.setItem('ih_type',res.data.result.name)
            this.$api.article.postihuserinfo(res.data.result.name).then(res=>{
              if(res.data.code=='200'){
                this.$router.push('/Patient')
              }
            })
           
          })
        },
        order(){
            this.$router.push('/order')
        },
        getdrug(){
            this.$router.push('/getdrug')
        },
        loadList(){
            let user = JSON.parse(localStorage.getItem('user'))
            if(user){
                this.user = user
                this.login_text = '退出登录'
            }else{
                this.login_text = '登录'
            }

        }
    },
    created() {
        this.loadList()
        
    },
    mounted(){
   

    }
  }
  </script>
  <style scopde lang="less">
    .my_img{
        width: 60px;
        border-radius: 50%;
    }
    .detail_box {
    height: 200px;
    background: rgba(255, 255, 255,0);
    box-shadow: 0px 0px 10px 0px rgba(119, 119, 119, 0.28);
    position: relative;
}

.m-top{
    margin-top: 20px;
}
.tx_box {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    padding: 24px 15px;
}

.tx {
    width: 67px;
    height: 67px;
    border-radius: 50%;
    display: block;
    border: 1px solid #FFBCB7;
}

.tx_info {
    margin-left: 32px;
    color: #fff;
}

.tx_info>.name {
    font-size: 19px;
    line-height: 26px;
    font-weight: 500;
    margin-bottom: 8px;
}

.user_account {
    font-size: 11px;
    line-height: 15px;
    color: #fff;
}

.info_box {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}
.UserRolesLabel{
    position: absolute;
    left: 10%;
    bottom: 10px;
    color: #fff;
}
.info_box>.box {
    width: 180px;
    color: #fff;
}

.box_value {
    height: 21px;
    font-size: 15px;
    line-height: 21px;
}

.box_label {
    font-size: 11px;
    line-height: 15px;
    margin-top: 5px;
    color: #AFFFD8;
}

.line {
    width: 1px;
    height: 25px;
    background: #AFFFD8;
}

.bj_btn {
    width: 216px;
    height: 56px;
    line-height: 56px;
    background: #FFC300;
    border-radius: 28px 0px 0px 28px;
    position: absolute;
    top: 64px;
    right: 0;
    color: #FFFFFF;
    font-size: 26px;
    padding-left: 30px;
    box-sizing: border-box;
}
.cz_btn{
    width: 216px;
    height: 56px;
    line-height: 56px;
    background: #FFC300;
    border-radius: 28px 0px 0px 28px;
    position: absolute;
    top: 150px;
    right: 0;
    color: #FFFFFF;
    font-size: 26px;
    padding-left: 30px;
    box-sizing: border-box;
}
.info {
    width: 420px;
    font-size: 24px;
    margin-left: 40px;
    color: #333;
}

.info>view {
    display: flex;
}

.info>view+view {
    margin-top: 20px;
}

.info .info_label {
    width: 100px;
    flex-shrink: 0;
}

.bj_box {
    text-align: center;
}

.editBox {
    border-radius: 5px;
    background: #fff;
    width: 90%;
    position: absolute;
    top: 50px;
    left: 50%;
    transform: translateX(-50%);
}

.editBox_title {
    text-align: center;
    line-height: 100px;
}

.btn {
    margin: 30px;
    display: flex;
    justify-content: space-between;
}
.LogOut{
    width: 80%;
    margin: 0 auto;
    margin-top: 20px;
    padding-bottom: 200px;
}
.LogOut button{
    border-radius: 8px;
}
  </style>